import React from "react";
import client_01 from "../Assets/clients/01.jpg";
import client_02 from "../Assets/clients/02.jpg";
import client_03 from "../Assets/clients/03.jpg";
import client_04 from "../Assets/clients/04.jpg";
import client_05 from "../Assets/clients/05.jpg";
import client_06 from "../Assets/clients/06.jpg";
import client_07 from "../Assets/clients/07.jpg";
import client_08 from "../Assets/clients/08.jpg";
import client_09 from "../Assets/clients/09.jpg";
import client_10 from "../Assets/clients/10.jpg";
import client_11 from "../Assets/clients/11.jpg";
import client_12 from "../Assets/clients/12.jpg";
import "animate.css";
const Clients = () => {
  return (
    <>
      <div className="col-lg-12 col-md-12 col-12 col-sm-12  ntgr-clients-home">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-md-10 col-12 col-sm-12">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-md-10 col-12 col-sm-12">
                <h1 className="text-center">Our Clients</h1>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-8 col-12 col-sm-12">
                <div
                  id="carouselExampleControlsNoTouching"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active animate__animated animate__backInRight">
                      <div className="row justify-content-center">
                        <div className="col-lg-3 col-md-3 col-3 col-sm-3">
                          <img src={client_01} className="width100" alt="..." />
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 col-sm-3">
                          <img src={client_02} className="width100" alt="..." />
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 col-sm-3">
                          <img src={client_03} className="width100" alt="..." />
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 col-sm-3">
                          <img src={client_04} className="width100" alt="..." />
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item animate__animated animate__backInRight">
                      <div className="row justify-content-center">
                        <div className="col-lg-3 col-md-3 col-3 col-sm-3">
                          <img src={client_05} className="width100" alt="..." />
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 col-sm-3">
                          <img src={client_06} className="width100" alt="..." />
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 col-sm-3">
                          <img src={client_07} className="width100" alt="..." />
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 col-sm-3">
                          <img src={client_08} className="width100" alt="..." />
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item animate__animated animate__backInRight">
                      <div className="row justify-content-center">
                        <div className="col-lg-3 col-md-3 col-3 col-sm-3">
                          <img src={client_09} className="width100" alt="..." />
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 col-sm-3">
                          <img src={client_10} className="width100" alt="..." />
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 col-sm-3">
                          <img src={client_11} className="width100" alt="..." />
                        </div>
                        <div className="col-lg-3 col-md-3 col-3 col-sm-3">
                          <img src={client_12} className="width100" alt="..." />
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleControlsNoTouching"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon ntgr-left-arrow"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleControlsNoTouching"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon ntgr-right-arrow"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Clients;
